import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate, Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import {newsCategoryArr} from '../../Category';

function ArticleList(props) {
  const [actions] = useOutlet('actions');
  const [articles, setArticles] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState(newsCategoryArr[0]);
  const [dimension] = useOutlet('dimension');
  const [banner, setBanner] = React.useState('');

  const [bannerDimension, setBannerDimension] = React.useState(null);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  React.useEffect(() => {
    async function fetchArticleData() {
      try {
        actions.setLoading(true);
        let resp = await actions.fetchDocuments(
          'Article_Default',
          {$and: [{label: '最新消息'}, {label: {$in: newsCategoryArr}}]},
          ['-created'],
          {offset: 0, limit: 350},
          null,
        );
        let dataList = resp.results.map((it) => {
          if (!it.id && it._id) {
            it.id = it._id.$oid;
          }
          return {...it};
        });
        setArticles(dataList);
      } catch (ex) {
        console.warn(ex);
      } finally {
        actions.setLoading(false);
      }
    }

    async function fetchBanner() {
      actions.setLoading(true);
      let bannerList = await actions.fetchDocuments('banner');
      bannerList = bannerList.results;
      let _banner = await bannerList.filter(
        (banner) => banner.type === 'news',
      )[0];
      actions.setLoading(false);
      setBanner(_banner.image);
    }
    fetchBanner();

    fetchArticleData();
  }, [actions]);

  return (
    <Wrapper>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="article-banner"
          src={banner}
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">NEWS</div>
        <div className="title">最新消息</div>
        <div className="tab-wrapper" style={{marginTop: 30}}>
          {newsCategoryArr.map((item, idx) => (
            <div
              className={`${selectedTab === item && 'selected-tab'} tab `}
              onClick={() => setSelectedTab(item)}
              key={idx}>
              {item}
            </div>
          ))}
        </div>
        <section className="grid" style={{flexDirection: 'column'}}>
          {articles
            .filter(
              (a) => (a?.label && a.label.indexOf(selectedTab) >= 0) || false,
            )
            .map((item, idx) => {
              const displayDate =
                item.label?.findIndex((l) => l === '院內公告') > -1;

              return (
                <div
                  onClick={() => {
                    navigate(`/news?id=${item.id}`);
                  }}
                  className="grid-item-large"
                  key={idx}
                  style={{marginBottom: dimension.rwd === 'desktop' ? 50 : 0}}>
                  <div
                    className="grid-item-img-large"
                    style={{
                      backgroundImage: `url(${item.image})`,
                    }}
                  />
                  <div className="grid-context-container">
                    {displayDate && (
                      <p className="grid-item-date" style={{marginTop: 10}}>
                        {new Date(item.created).toLocaleDateString('en-ZA')}
                      </p>
                    )}
                    <p className="grid-item-title">{item.title}</p>
                    <p
                      className="grid-item-outline outline"
                      style={{marginTop: 10}}>
                      {item.outline}
                    </p>
                  </div>
                </div>
              );
            })}
        </section>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .outline {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export default ArticleList;
